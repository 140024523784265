import React from 'react';

import Layout from '../../layout/ConsultantLayout';
import PersonalAuthenticationView from '../../views/consultant/PersonalAuthenticationView';

const Authentication = (): React.ReactElement => {
  return (
    <Layout>
      <PersonalAuthenticationView />
    </Layout>
  );
};

export default Authentication;
